import { createUseStyles } from 'react-jss';
import icon from '../../assets/svg/more-info.svg';
import clock from '../../assets/svg/clock.svg';
import note from '../../assets/svg/note.svg';

const useStyles = createUseStyles({
  serviceBox: {
    border: '2px solid #EFF2F3',
    borderRadius: '1.4rem',
    padding: '2rem',
    width: 'calc((100% - 2rem) / 3)',
    marginBottom: '1rem',
    marginRight: '1rem',
    '&:nth-of-type(3n)': {
      marginRight: '0',
    },

    '@media(max-width:992px)': {
      width: 'calc((100% - 2rem) / 2)',
      '&:nth-of-type(3n)': {
        marginRight: '1rem',
      },
      '&:nth-of-type(2n)': {
        marginRight: '0',
      },
    },

    '@media(max-width:768px)': {
      width: '100%',
      '&:nth-of-type(3n)': {
        marginRight: '0',
      },
      marginRight: '0',
    },

    '& .header-wrapper': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      marginBottom: '1rem',
      '& >p:first-of-type': {
        fontSize: '1.2rem',
        fontWeight: '600',
        color: '#42515A',
        marginBottom: '0.5rem',
        display: 'inline-flex',
        alignItems: 'center',
        '&:before': {
          content: '""',
          minWidth: '1.6rem',
          minHeight: '1.6rem',
          marginRight: '0.5rem',
          backgroundImage: `url(${icon})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
      },
      '& h6': {
        fontWeight: '600',
        marginBottom: '0',
        color: ' #27BDBE',
      },
    },

    '& .buttons-wrapper': {
      marginTop: '2rem',
      display: 'flex',
      '& .btn': {
        padding: '0 !important',
        backgroundColor: 'transparent',
        color: '#42515A',
        marginLeft: '2rem',
      },
      '& button:first-of-type': {
        minWidth: '3.6rem',
        minHeight: '3.6rem',
        border: 'none',
        backgroundColor: 'transparent',
        display: 'inline-flex',
        padding: '0',
      },
    },
  },
  priceWrapper: {
    display: 'flex',
    flexDirection: 'column',
    fontWeight: '600',
    '& >span': {
      fontSize: '1rem',
      textTransform: 'uppercase',
      color: '#8E979C',
      letterSpacing: '1.5px',
    },
    '& p': {
      color: '#42515A',
      '& span': {
        color: '#8E979C',
      },
    },
  },
  modalContent: {
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    width: '53%',
    margin: 'auto',
    padding: '1.8rem',
    position: 'relative',
    marginTop: '5rem',
    marginBottom: '5rem',
    '@media(max-width:1200px)': {
      width: '80%',
      padding: '1rem',
    },
    '@media(max-width:992px)': {
      marginTop: '2rem',
      marginBottom: '2rem',
    },
  },
  image: {
    paddingTop: '45%',
    borderRadius: '1.4rem',
    overflow: 'hidden',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
  text: {
    padding: '2rem',
    '& h4': {
      color: '#27BDBE',
      fontFamily: "'Varela Round', sans-serif",
    },
    '& >p': {
      color: '#71838D',
      fontWeight: '600',
      fontSize: '1.6rem',
    },
    '& >p:first-of-type': {
      margin: '1rem 0 2rem 0',
      display: 'inline-flex',
      alignItems: 'center',
      '&:before': {
        content: '""',
        minWidth: '1.6rem',
        minHeight: '1.6rem',
        marginRight: '0.5rem',
        backgroundImage: `url(${icon})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      },
    },
    '& p.description': {
      fontSize: '1.4rem',
    },
    '@media(max-width:1200px)': {
      padding: '1.5rem',
    },
    '@media(max-width:576px)': {
      padding: '1rem 0.5rem 0.5rem 0.5rem',
    },
  },
  packagesWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  package: {
    width: 'calc((100% - 2rem) / 2)',
    border: '2px solid #EFF2F3',
    borderRadius: '1.4rem',
    padding: '1.6rem',
    marginTop: '2rem',
    '&:nth-of-type(2n)': {
      marginLeft: '2rem',
    },
    '& h6': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '1rem',
    },
    '& >div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      '& p': {
        fontWeight: '600',
        fontSize: '1.4rem',
        color: '#71838D',
        display: 'inline-flex',
        alignItems: 'center',
        '&:before': {
          content: '""',
          minWidth: '1.6rem',
          minHeight: '1.6rem',
          marginRight: '0.5rem',
          backgroundImage: `url(${clock})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
        '&:last-of-type': {
          marginTop: '1rem',
          '&:before': {
            backgroundImage: `url(${note})`,
          },
        },
      },
    },
    '& >div:last-of-type': {
      // border: '1px solid red',
      marginTop: '2rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      '@media(max-width:576px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
        '& button': {
          marginTop: '1rem',
        },
      },
    },
    '@media(max-width:1200px)': {
      padding: '1rem',
    },
    '@media(max-width:768px)': {
      width: '100%',
      '&:nth-of-type(2n)': {
        marginLeft: '0',
      },
    },
  },
  closeModal: {
    position: 'absolute',
    top: '0',
    right: '-6rem',
    backgroundColor: 'transparent',
    width: '5.2rem',
    height: '5.2rem',
    padding: '0',
    border: 'none',
    zIndex: '1',
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    '@media(max-width:768px)': {
      right: '0',
    },
  },
});

export default useStyles;
