import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          fontWeight: 700,
          borderRadius: '1.4rem',
          padding: '1.2rem 2.2rem !important',
          backgroundColor: '#27BDBE !important',
        },
        sizeMedium: {
          color: '#ffffff',
          backgroundColor: '#009ddb',
          borderColor: '#009ddb',
          borderWidth: 2,
          padding: '1.5rem 2.5rem',
          fontSize: '1.4rem',
          lineHeight: '1.6rem',
          opacity: 1,
          boxShadow: 'none',

          '&:hover': {
            color: '#ffffff',
            backgroundColor: '#82d5f3',
            borderColor: '#82d5f3',
            borderWidth: 2,
            boxShadow: '0px 12px 10px 0px rgba(0,0,0,0.05)',
          },
        },
      },
    },

    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: '"Montserrat", sans-serif',
          fontWeight: '600',
          '&.Mui-selected': {
            backgroundColor: 'rgba(39,189,190, 0.6)',
            '&:hover': {
              backgroundColor: 'rgba(39,189,190, 0.6)',
            },
          },
        },
      },
    },

    MuiModal: {
      styleOverrides: {
        root: {
          overflowY: 'auto',
        },
      },
    },

    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          // backgroundColor: 'white',
          // borderRadius: '1.4rem',
          '& .PrivatePickersYear-root': {
            '& button.Mui-selected': {
              backgroundColor: '#27BDBE',
            },
          },
          '& button.MuiPickersDay-today': {
            border: '1px solid #27BDBE',
          },
          '& button.MuiPickersDay-root.Mui-selected': {
            backgroundColor: '#27BDBE',
          },
        },
      },
    },

    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          borderRadius: '1.4rem',
          boxShadow: 'none',
          border: '2px solid #D9DCDE',
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          '&.calendar-wrapper ': {
            '& .MuiSvgIcon-root': {
              color: '#27BDBE',
              fontSize: '2rem',
            },
          },
        },
      },
    },

    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: '1rem 1.4rem !important',
          borderRadius: '1.4rem !important',
          fontFamily: '"Montserrat", sans-serif',
          border: '2px solid #d9dcde !important',
          color: '#42515A',
          fontSize: '1.4rem',
          fontWeight: '600',
          display: 'flex',
          alignItems: 'center',
          '& .MuiInputBase-input': {
            padding: '0 !important',
            // borderRadius: '1rem',
            // fontFamily: '"Montserrat", sans-serif',
            // border: '2px solid #d9dcde !important',
            // color: '#42515A',
            // fontSize: '1.4rem',
            // fontWeight: '600',
            // display: 'flex',
            // alignItems: 'center',
          },

          '& fieldset': {
            border: 'none',
          },
          '&:hover fieldset': {
            border: 'none',
          },
          '&.Mui-focused fieldset': {
            border: 'none',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '0',
          paddingRight: '1rem',
          '& svg': {
            minWidth: '2rem',
            minHeight: '2rem',
            border: '2px solid #D9DCDE',
            borderRadius: '5px',
            // fill: '#27BDBE',
            fill: 'transparent',
          },
          '&.Mui-checked': {
            '& svg': {
              fill: '#27BDBE',
            },
          },
          '& .MuiTouchRipple-root': {
            display: 'none',
          },
        },
      },
    },
  },
});

export default theme;
