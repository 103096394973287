import React, { useState, useEffect } from 'react';
import useStyles from '../../components/PersonalData/PersonalData.style';
import TextField from '@mui/material/TextField';
import Cart from '../Cart/Cart';

const PersonalData = ({
  personalData,
  setPersonalData,
  cart,
  setCart,
  setIsFormValid,
}) => {
  const classes = useStyles();

  function checkFormValues() {
    let isFormValid = true;
    if (personalData.name === '') {
      isFormValid = false;
    }
    if (personalData.surname === '') {
      isFormValid = false;
    }
    if (personalData.mail === '') {
      isFormValid = false;
    }
    if (personalData.phone === '') {
      isFormValid = false;
    }

    return isFormValid;
  }

  useEffect(() => {
    setIsFormValid(checkFormValues);
  }, [personalData]);

  const handleNameChange = (event) => {
    setPersonalData({
      ...personalData,
      name: event.target.value,
    });
  };
  const handleSurnameChange = (event) => {
    setPersonalData({
      ...personalData,
      surname: event.target.value,
    });
  };
  const handleMailChange = (event) => {
    setPersonalData({
      ...personalData,
      mail: event.target.value,
    });
  };
  const handlePhoneChange = (event) => {
    setPersonalData({
      ...personalData,
      phone: event.target.value,
    });
  };
  const handleCityChange = (event) => {
    setPersonalData({
      ...personalData,
      city: event.target.value,
    });
  };
  const handleCompanyChange = (event) => {
    setPersonalData({
      ...personalData,
      company: event.target.value,
    });
  };
  const handleAddInfoChange = (event) => {
    setPersonalData({
      ...personalData,
      addInfo: event.target.value,
    });
  };

  return (
    <div className={classes.personalData}>
      <h5>Ostatnim krokiem będzie uzupełnienie danych osobowych</h5>
      <Cart cart={cart} setCart={setCart} />
      <div className={classes.formWrapper}>
        <div className={classes.input}>
          {' '}
          <label>Imię</label>
          <TextField
            fullWidth
            placeholder="Imię"
            variant="outlined"
            onChange={handleNameChange}
            value={personalData.name}
          />
        </div>{' '}
        <div className={classes.input}>
          {' '}
          <label>Nazwisko</label>
          <TextField
            fullWidth
            placeholder="Nazwisko"
            variant="outlined"
            onChange={handleSurnameChange}
            value={personalData.surname}
          />
        </div>
        <div className={classes.input}>
          {' '}
          <label>Adres e-mail</label>
          <TextField
            type="email"
            fullWidth
            placeholder="Adres e-mail"
            variant="outlined"
            onChange={handleMailChange}
            value={personalData.mail}
          />
        </div>
        <div className={classes.input}>
          {' '}
          <label>Numer telefonu</label>
          <TextField
            type="number"
            fullWidth
            placeholder="Numer telefonu"
            variant="outlined"
            onChange={handlePhoneChange}
            value={personalData.phone}
          />
        </div>
        <div className={classes.input}>
          {' '}
          <label>Nazwa placówki / Firma (opcjonalnie)</label>
          <TextField
            fullWidth
            placeholder="Nazwa placówki / Firma"
            variant="outlined"
            onChange={handleCompanyChange}
            value={personalData.company}
          />
        </div>
        <div className={classes.input}>
          {' '}
          <label>Miejscowość (opcjonalnie)</label>
          <TextField
            fullWidth
            placeholder="Miejscowość"
            variant="outlined"
            onChange={handleCityChange}
            value={personalData.city}
          />
        </div>
        <div className={classes.input}>
          {' '}
          <label>Informacje dodatkowe (opcjonalnie)</label>
          <TextField
            fullWidth
            placeholder="Informacje dodatkowe"
            variant="outlined"
            onChange={handleAddInfoChange}
            value={personalData.addInfo}
            multiline
            rows={4}
          />
        </div>
      </div>
    </div>
  );
};

export default PersonalData;
