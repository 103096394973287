import React, { useState, useEffect } from 'react';
import useStyles from '../Cart/Cart.style';
import removeIcon from '../../assets/svg/removeIcon.svg';

const CartItem = ({ title, price, id, cart, setCart, disabledRemoveBtn }) => {
  const classes = useStyles();
  function handleRemoveCartItem() {
    let newCart = cart.filter(function (obj) {
      return obj.id !== id;
    });

    setCart(newCart);
  }

  return (
    <>
      <div className={classes.cartItem}>
        <div>
          <p>{title}</p>
          <span>XXX Pakiet Siłownia Biocircut + siłownia stand. Open</span>
        </div>
        <p className="price">{price} zł</p>
        {!disabledRemoveBtn && (
          <button className="remove" onClick={handleRemoveCartItem}>
            <img alt="" src={removeIcon} />
          </button>
        )}
      </div>
    </>
  );
};

export default CartItem;
