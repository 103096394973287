import React, { useState, useEffect } from 'react';
import NavigationBox from '../NavigationBox/NavigationBox';
import useStyles from './Navigation.style';

const Navigation = ({ navigationStep }) => {
  const classes = useStyles();

  return (
    <div className={classes.navigation}>
      <NavigationBox
        number="001"
        text="Odpowiedz na pytania"
        isActive={navigationStep === 1 ? true : false}
      />
      <NavigationBox
        number="002"
        text="Wybierz usługi"
        isActive={navigationStep === 2 ? true : false}
      />
      <NavigationBox
        number="003"
        text="Uzupełnij dane"
        isActive={navigationStep === 3 ? true : false}
      />
      <NavigationBox
        number="004"
        text="Podsumowanie"
        isActive={navigationStep === 4 ? true : false}
      />
    </div>
  );
};

export default Navigation;
