import React, { useState, useEffect } from 'react';
import useStyles from '../../components/Questionnaire/Questionnaire.style';
import TextField from '@mui/material/TextField';
import hotel from '../../assets/svg/hotel.svg';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const Questionnaire = ({
  questionnaireData,
  setQuestionnaireData,
  setIsFormValid,
}) => {
  const classes = useStyles();

  function checkFormValues() {
    let isFormValid = true;
    if (questionnaireData.target === '') {
      isFormValid = false;
    }
    if (questionnaireData.peopleNumber === '') {
      isFormValid = false;
    }
    if (questionnaireData.startDate === '') {
      isFormValid = false;
    }
    if (questionnaireData.days === '') {
      isFormValid = false;
    }
    if (questionnaireData.ageGroup === '') {
      isFormValid = false;
    }
    return isFormValid;
  }

  useEffect(() => {
    setIsFormValid(checkFormValues);
  }, [questionnaireData]);

  const handleTargetChange = (event) => {
    // setTarget(event.target.value);
    setQuestionnaireData({ ...questionnaireData, target: event.target.value });
  };

  const handlePeopleNumberChange = (event) => {
    setQuestionnaireData({
      ...questionnaireData,
      peopleNumber: event.target.value,
    });
  };

  const handleDaysChange = (event) => {
    setQuestionnaireData({
      ...questionnaireData,
      days: event.target.value,
    });
  };

  const handleTransportChange = (event) => {
    setQuestionnaireData({
      ...questionnaireData,
      transport: event.target.checked,
    });
  };

  const handleAgeGroupChange = (event) => {
    // setAgeGroup(event.target.value);
    setQuestionnaireData({
      ...questionnaireData,
      ageGroup: event.target.value,
    });
  };

  const handleMealsChange = (event) => {
    setQuestionnaireData({ ...questionnaireData, meals: event.target.checked });
  };

  const handleHotelChange = (event) => {
    setQuestionnaireData({ ...questionnaireData, hotel: event.target.checked });
  };

  return (
    <div className={classes.questionnaire}>
      <h5>Wypełnij krótką ankietę aby rozpocząć planowanie wycieczki</h5>
      <div className={classes.mainInfo}>
        <div className={classes.input}>
          {' '}
          <label>Wybierz cel podróży</label>
          <Select
            displayEmpty
            fullWidth
            value={questionnaireData.target}
            onChange={handleTargetChange}
          >
            <MenuItem value="">
              <em>Cel podróży</em>
            </MenuItem>
            <MenuItem value={'Zabawa w centrum'}>Zabawa w centrum</MenuItem>
            <MenuItem value={'Rehabilitacja'}>Rehabilitacja</MenuItem>
            <MenuItem value={'Zawody sportowe'}>Zawody sportowe</MenuItem>
          </Select>{' '}
        </div>
        <div className={classes.input}>
          <label>Liczba osób (opiekuni / os. korzystające z atrakcji)</label>
          <TextField
            type="number"
            fullWidth
            placeholder="Wybierz liczbę osób"
            variant="outlined"
            onChange={handlePeopleNumberChange}
            value={questionnaireData.peopleNumber}
          />
        </div>{' '}
        <div className={classes.input}>
          <label>Data rozpoczęcia zabawy</label>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              value={questionnaireData.startDate}
              onChange={(newStartDate) => {
                setQuestionnaireData({
                  ...questionnaireData,
                  startDate: newStartDate,
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  placeholder="Wybierz datę"
                  variant="outlined"
                  className="calendar-wrapper"
                />
              )}
            />
          </LocalizationProvider>
        </div>{' '}
        <div className={classes.input}>
          <label>Liczba dni</label>
          <TextField
            type="number"
            fullWidth
            placeholder="Wybierz liczbę dni"
            variant="outlined"
            onChange={handleDaysChange}
            value={questionnaireData.days}
          />
        </div>
        <div className={classes.checkbox}>
          <label>Chcę skorzystać również z transportu (opcjonalnie)</label>
          <div>
            {' '}
            <Checkbox
              checked={questionnaireData.transport}
              onChange={handleTransportChange}
            />{' '}
            <p>Wybieram transport</p>
          </div>
        </div>{' '}
        <div className={classes.input}>
          <label>Grupa wiekowa</label>
          <Select
            displayEmpty
            fullWidth
            value={questionnaireData.ageGroup}
            onChange={handleAgeGroupChange}
          >
            <MenuItem value="">
              <em>Wybierz grupę wiekową</em>
            </MenuItem>
            <MenuItem value="poniżej 10">poniżej 10</MenuItem>
            <MenuItem value="10-15">10-15</MenuItem>
            <MenuItem value="powyżej 20">powyżej 20</MenuItem>
          </Select>{' '}
        </div>{' '}
        <div className={classes.checkbox}>
          <label>Chcę skorzystać również z posiłków (opcjonalnie)</label>
          <div>
            {' '}
            <Checkbox
              checked={questionnaireData.meals}
              onChange={handleMealsChange}
            />{' '}
            <p>Wybieram posiłki</p>
          </div>
        </div>{' '}
        <div className={classes.checkbox}>
          <label>Chcę skorzystać również z hotelu (opcjonalnie)</label>
          <div>
            {' '}
            <Checkbox
              checked={questionnaireData.hotel}
              onChange={handleHotelChange}
            />{' '}
            <p>Wybieram hotel</p>
          </div>
        </div>
      </div>
      <div className={classes.hotel}>
        <div className="title-wrapper">
          <img src={hotel} alt="" />
          <h5>Hotel</h5>
        </div>
        <div className="wrapper">
          <div className={classes.input}>
            <label>Data zameldowania (opcjonalnie)</label>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={questionnaireData.hotelStartDate}
                onChange={(newStartDate) => {
                  setQuestionnaireData({
                    ...questionnaireData,
                    hotelStartDate: newStartDate,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Wybierz datę"
                    variant="outlined"
                    className="calendar-wrapper"
                  />
                )}
              />
            </LocalizationProvider>
          </div>{' '}
          <div className={classes.input}>
            <label>Data wymeldowania (opcjonalnie)</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                value={questionnaireData.hotelEndDate}
                onChange={(newStartDate) => {
                  setQuestionnaireData({
                    ...questionnaireData,
                    hotelEndDate: newStartDate,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    placeholder="Wybierz datę"
                    variant="outlined"
                    className="calendar-wrapper"
                  />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questionnaire;
