import React, { useState, useEffect } from 'react';
import useStyles from './ServiceBox.style';
import Modal from '@mui/material/Modal';
import cartImg from '../../assets/svg/cart-btn.svg';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import closeModal from '../../assets/svg/close-modal.svg';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#68747B',
    color: '#F8F8F8',
    boxShadow: '0px 12px 10px rgba(0, 0, 0, 0.05);',
    fontSize: '1.2rem',
    padding: '1.5rem 2.5rem',
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: '600',
  },
}));

const ServiceBox = ({ id, peopleNumber, setCart, cart, acf, title }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function handleClick() {
    setCart([
      ...cart,
      {
        id: id,
        title: title,
        price: peopleNumber * parseInt(acf.cena),
      },
    ]);
    console.log(cart);
  }

  return (
    <>
      {acf && (
        <div className={classes.serviceBox}>
          <div className="header-wrapper">
            <LightTooltip arrow placement="top-start" title={acf.tooltip}>
              <p>Dowiedz się więcej</p>
            </LightTooltip>
            <h6>{title}</h6>
          </div>
          <div className={classes.priceWrapper}>
            <span>Koszt</span>
            <p>
              {peopleNumber * parseInt(acf.cena)} zł / <span>grupa</span>
            </p>
            <p>
              {acf.cena} zł/ <span>1 os</span>
            </p>
          </div>
          <div className="buttons-wrapper">
            <button className="" onClick={handleClick}>
              <img src={cartImg} alt="" />
            </button>
            <button className="btn" onClick={handleOpen}>
              Szczegóły
            </button>
          </div>
          <Modal open={open} onClose={handleClose}>
            <div className={classes.modalContent}>
              <button onClick={handleClose} className={classes.closeModal}>
                <img src={closeModal} alt="" />
              </button>

              <div className={classes.image}>
                <img src={acf.sekcja_opisowa.zdjecie} alt="" />
              </div>
              <div className={classes.text}>
                {' '}
                <h4>{title}</h4>
                <p>Czym jest {title && String(title).toLowerCase()}?</p>
                <p className="description">{acf.sekcja_opisowa.opis}</p>
                <div className={classes.packagesWrapper}>
                  {acf.pakiety.length > 0 &&
                    acf.pakiety.map(
                      (pakiet) =>
                        (pakiet = (
                          <div className={classes.package}>
                            <h6>{pakiet.pakiet.tytul_pakietu}</h6>
                            <div>
                              <p>{pakiet.pakiet.czas_trwania}</p>
                              <p>{pakiet.pakiet.opis_pakietu}</p>
                            </div>
                            <div>
                              <div className={classes.priceWrapper}>
                                <span>Koszt</span>
                                <p>
                                  {peopleNumber *
                                    parseInt(pakiet.pakiet.cena_za_osobe)}
                                  zł / <span>grupa</span>
                                </p>
                                <p>
                                  {pakiet.pakiet.cena_za_osobe} zł /{' '}
                                  <span>1 os</span>
                                </p>
                              </div>
                              <button className="btn blue-btn">
                                Dodaj do koszyka
                              </button>
                            </div>
                          </div>
                        ))
                    )}
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};

export default ServiceBox;
