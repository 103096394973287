import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles({
  navigation: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    marginBottom: '4rem',
    '@media(max-width:768px)': {
      flexWrap: 'wrap',
    },
  },
  navigationBox: {
    width: '25%',
    padding: '2.5rem 4rem',
    borderRight: '2px solid #EAEDEE',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '&:last-of-type': {
      borderRight: 'unset',
    },
    '& span': {
      fontFamily: "'Varela Round', sans-serif",
      color: '#7DD7D8',
      transition: 'all 250ms ease',
      fontSize: '2.4rem',
    },
    '& p': {
      fontSize: '2rem',
      fontWeight: '600',
      color: '#B8C2C8',
      margin: '1.2rem 0 4rem 0',
      letterSpacing: '0.15px',
      transition: 'all 250ms ease',
    },
    '& img': {
      transition: 'all 250ms ease',
      maxWidth: '4.3rem',
      maxHeight: '4.3rem',
      objectFit: 'contain',
    },

    '@media(max-width:1200px)': {
      padding: '2rem',
      '& span': {
        fontSize: '2rem',
      },
      '& p': {
        fontSize: '1.6rem',
      },
    },
    '@media(max-width:768px)': {
      width: '50%',
      '&:first-of-type': {
        borderBottom: '2px solid #EAEDEE',
      },
      '&:nth-of-type(2)': {
        borderBottom: '2px solid #EAEDEE',
        borderRight: 'unset',
      },
    },
    '@media(max-width:490px)': {
      width: '100%',
      flexDirection: 'row',
      alignItems: 'center',
      '&:first-of-type': {
        borderRight: 'unset',
      },
      '&:nth-of-type(3)': {
        borderBottom: '2px solid #EAEDEE',
        borderRight: 'unset',
      },
      '& p': {
        margin: '1.2rem 0 2rem 0',
      },
    },
  },
  active: {
    '& span': {
      color: '#27BDBE',
    },
    '& p': { color: '#42515A' },
  },
});
export default useStyles;
