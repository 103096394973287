import React, { useState, useEffect } from 'react';
import useStyles from '../../components/Navigation/Navigation.style';
import arrow from '../../assets/svg/navigation-arrow.svg';
import arrowActive from '../../assets/svg/navigation-arrow-active.svg';

const NavigationBox = ({ number, text, isActive }) => {
  const classes = useStyles();

  return (
    <div
      className={
        isActive
          ? classes.active + ' ' + classes.navigationBox
          : classes.notActive + ' ' + classes.navigationBox
      }
    >
      <div>
        <span>{number}</span>
        <p>{text}</p>
      </div>

      <img src={isActive ? arrowActive : arrow} alt="" />
    </div>
  );
};

export default NavigationBox;
