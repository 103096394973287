import React, { useState, useEffect } from 'react';
import useStyles from '../Services/Services.style';
import servicesImg from '../../assets/svg/services.svg';
import ServiceBox from '../ServiceBox/ServiceBox';
import Cart from '../Cart/Cart';
import getCpt from '../../api/getCpt';
import { BallTriangle } from 'react-loader-spinner';

const Services = ({ peopleNumber, cart, setCart }) => {
  const classes = useStyles();

  const [services, setServices] = useState(false);
  const [category, setCategory] = useState({
    allCategory: '25',
    suggestedCategory: '30',
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCpt(`usluga-eruch?per_page=100`).then((result) => {
      setServices(result);
      setLoading(false);
      console.log(result);
    });
  }, []);

  const handleCategoryChange = (event) => {
    let categories = event.target.value.split(',');
    setCategory({
      allCategory: categories[0],
      suggestedCategory: categories[1],
    });
  };

  return (
    <div className={classes.services}>
      <div className="title-wrapper">
        <img src={servicesImg} alt="" />
        <h5>Wybierz kategorię oraz wybrane usługi</h5>
      </div>
      <div className={classes.serviceCategories}>
        <button
          onClick={handleCategoryChange}
          value="25,30"
          className={category.allCategory === '25' ? 'active' : ''}
        >
          Rekreacyjne
        </button>
        <button
          onClick={handleCategoryChange}
          value="26,31"
          className={category.allCategory === '26' ? 'active' : ''}
        >
          Sportowe
        </button>
        <button
          onClick={handleCategoryChange}
          value="27,29"
          className={category.allCategory === '27' ? 'active' : ''}
        >
          Rehabilitacyjne
        </button>
        <button
          onClick={handleCategoryChange}
          value="28,28"
          className={category.allCategory === '28' ? 'active' : ''}
        >
          Specjalne
        </button>
      </div>
      <Cart cart={cart} setCart={setCart} />
      <>
        {loading && (
          <div className={classes.loaderWrapper}>
            <BallTriangle
              heigth="70"
              width="70"
              color="#27BDBE"
              ariaLabel="loading-indicator"
            />
          </div>
        )}

        {!loading && (
          <>
            <div className="suggested-services services-wrapper">
              <h6>Proponowane usługi</h6>
              <div>
                {services && (
                  <>
                    {services
                      .filter(
                        (service) =>
                          parseInt(service.usluga_eruch_category) ===
                          parseInt(category.suggestedCategory)
                      )
                      .map(
                        (service) =>
                          (service = (
                            <ServiceBox
                              peopleNumber={peopleNumber}
                              key={service.id}
                              id={service.id}
                              title={[service.title.rendered]}
                              cart={cart}
                              setCart={setCart}
                              acf={service.acf}
                            />
                          ))
                      )}
                  </>
                )}
              </div>
            </div>
            <div className="all-services services-wrapper">
              <h6>Pozostałe usługi</h6>
              <div>
                {services && (
                  <>
                    {services
                      .filter(
                        (service) =>
                          parseInt(service.usluga_eruch_category) ===
                          parseInt(category.allCategory)
                      )
                      .map(
                        (service) =>
                          (service = (
                            <ServiceBox
                              peopleNumber={peopleNumber}
                              key={service.id}
                              id={service.id}
                              title={[service.title.rendered]}
                              cart={cart}
                              setCart={setCart}
                              acf={service.acf}
                            />
                          ))
                      )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

export default Services;
