import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  summaryBox: {
    border: '2px solid #EFF2F3',
    borderRadius: '1.4rem',
    padding: '2rem',
    marginBottom: '2rem',
  },
  title: {
    color: '#42515A',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: '1rem',
    },
  },
  summaryInfo: {
    padding: '2rem 2rem 0 2rem',
    '@media(max-width:768px)': {
      padding: '2rem 0 0 0',
    },
    '& > div': {
      display: 'flex',
      flexWrap: 'wrap',

      '& div': {
        fontWeight: '600',
        fontSize: '1.6rem',
        color: '#42515A',
        width: '50%',
        paddingBottom: '2rem',

        '@media(max-width:768px)': {
          width: '100%',
        },
        '& span': {
          fontSize: '1.4rem',
          color: '#8E979C',
          marginBottom: '1rem',
        },
      },
    },
  },
  cartItem: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    justifyContent: 'space-between',
    borderBottom: '2px solid #EFF2F3',
    padding: '1rem 0',
    '&:last-of-type': {
      borderBottom: 'unset',
    },
    '& >div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '2',
      paddingBottom: 'unset',
      width: 'unset',
      marginRight: '1rem',
      '& span': {
        fontSize: '1.2rem',
        color: '#71838D',
        marginTop: '0.5rem',
      },
    },
    '& p.price': {
      color: '#27BDBE',
      fontSize: '2rem',
      padding: '0 !important',
    },
  },
});

export default useStyles;
