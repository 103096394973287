import React, { useState, useEffect } from 'react';
import useStyles from './Cart.style';
import cartImg from '../../assets/svg/cart.svg';
import Modal from '@mui/material/Modal';
import summaryCart from '../../assets/svg/summary-cart.svg';
// import removeIcon from '../../assets/svg/removeIcon.svg';
import closeModal from '../../assets/svg/close-modal.svg';
import CartItem from '../CartItem/CartItem';

const Cart = ({ cart, setCart }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function calcPrice() {
    let price = 0;
    if (cart.length > 0) {
      for (let i = 0; i < cart.length; i++) {
        price = price + cart[i].price;
      }
    }
    return price;
  }

  return (
    <div className={classes.cart}>
      <div>
        <img src={cartImg} alt="" />
        <div>
          <p>
            Aktualnie wybrałeś {cart.length} usługi o wartości {calcPrice()} zł.
          </p>
          <span>Koszt nie obejmuje zakwaterowania.</span>
        </div>
      </div>
      <button onClick={handleOpen} className="btn border-btn">
        Wyświetl wybrane usługi
      </button>
      <Modal open={open} onClose={handleClose}>
        <>
          <div className={classes.modalCart}>
            {' '}
            <button onClick={handleClose} className={classes.closeModal}>
              <img src={closeModal} alt="" />
            </button>
            <div className="title-wrapper">
              <img alt="" src={summaryCart} />
              <p>Poniżej znajdują się wybrane przez Ciebie usługi</p>
            </div>
            {cart.length > 0 && (
              <>
                {cart.map(
                  (cartItem) =>
                    (cartItem = (
                      <CartItem
                        title={cartItem.title}
                        price={cartItem.price}
                        id={cartItem.id}
                        cart={cart}
                        setCart={setCart}
                      />
                    ))
                )}
              </>
            )}
            {cart.length === 0 && <p>Twój koszyk jest pusty</p>}
          </div>
        </>
      </Modal>
    </div>
  );
};

export default Cart;
