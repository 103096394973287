import { ClassNames } from '@emotion/react';
import React, { useState, useEffect } from 'react';
import useStyles from './Summary.style';
import summaryData from '../../assets/svg/summary-data.svg';
import summaryCart from '../../assets/svg/summary-cart.svg';
import summaryAdditional from '../../assets/svg/summary-additional.svg';
import CartItem from '../CartItem/CartItem';

const Summary = ({ cart, questionnaireData, personalData }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.summaryBox + ' ' + classes.summaryCart}>
        <div className={classes.title}>
          <img src={summaryCart} alt="" />
          <p>Poniżej znajdują się wybrane przez Ciebie usługi</p>
        </div>
        <div className={classes.summaryInfo}>
          {cart.length > 0 && (
            <>
              {cart.map(
                (cartItem) =>
                  (cartItem = (
                    <div className={classes.cartItem}>
                      <div>
                        <p>{cartItem.title}</p>
                        <span>
                          XXX Pakiet Siłownia Biocircut + siłownia stand. Open
                        </span>
                      </div>
                      <p className="price">{cartItem.price} zł</p>
                    </div>
                  ))
              )}
            </>
          )}
        </div>
      </div>

      <div className={classes.summaryBox}>
        <div className={classes.title}>
          <img src={summaryAdditional} alt="" />
          <p>Dodatkowe usługi</p>
        </div>
        <div className={classes.summaryInfo}>
          <div className={classes.cartItem}>
            <div>
              <p>Wyżywienie</p>
              <span>Wyżywienie na {questionnaireData.days} dni </span>
            </div>
            <p className="price">
              {questionnaireData.meals === true ? 'Tak' : 'Nie'}
            </p>
          </div>

          <div className={classes.cartItem}>
            <div>
              <p>Transport</p>
              <span>Transport do ośrodka Hullo</span>
            </div>
            <p className="price">
              {' '}
              {questionnaireData.transport === true ? 'Tak' : 'Nie'}
            </p>
          </div>
        </div>
      </div>
      <div className={classes.summaryBox}>
        <div className={classes.title}>
          <img src={summaryData} alt="" />
          <p>Poniżej znajdują się twoje dane</p>
        </div>
        <div className={classes.summaryInfo}>
          <div>
            <div>
              <span>Cel podróży</span>
              <p>{questionnaireData.target}</p>
            </div>
            <div>
              <span>Ilość osób</span>
              <p>{questionnaireData.peopleNumber}</p>
            </div>
            <div>
              <span>Data przyjazdu</span>
              <p>
                {questionnaireData.startDate &&
                  questionnaireData.startDate.toLocaleDateString('en-US')}
              </p>
            </div>
            <div>
              <span>Grupa wiekowa</span>
              <p>{questionnaireData.ageGroup}</p>
            </div>
            <div>
              <span>Chcę skorzystać z hotelu</span>
              <p>{questionnaireData.hotel === true ? 'Tak' : 'Nie'}</p>
            </div>
          </div>
          <div>
            <div>
              <span>Imię oraz nazwisko</span>
              <p>
                {personalData.name} {personalData.surname}
              </p>
            </div>
            <div>
              <span>Adres e-mail</span>
              <p>{personalData.mail}</p>
            </div>
            <div>
              <span>Numer telefonu</span>
              <p>{personalData.phone}</p>
            </div>
            <div>
              <span>Nazwa placówki</span>
              <p>{personalData.company}</p>
            </div>
            <div>
              <span>Miejscowość</span>
              <p>{personalData.city}</p>
            </div>
            <div>
              <span>Informacje dodatkowe</span>
              <p>{personalData.addInfo}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Summary;
