import React, { useState, useEffect } from 'react';
import './App.css';
import Navigation from './components/Navigation/Navigation';
import Questionnaire from './components/Questionnaire/Questionnaire';
import Services from './components/Services/Services';
import useStyles from './App.style';
import PersonalData from './components/PersonalData/PersonalData';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import download from './assets/svg/download.svg';
import contact from './assets/svg/contact.svg';

import Summary from './components/Summary/Summary';

// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import TextField from '@mui/material/TextField';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const App = ({ children }) => {
  const [plannerStep, setPlannerStep] = useState(1);

  // kwestionariusz
  const [questionnaireData, setQuestionnaireData] = useState({
    target: '',
    peopleNumber: '',
    startDate: '',
    days: '',
    transport: '',
    ageGroup: '',
    meals: '',
    hotel: '',
    hotelStartDate: '',
    hotelEndDate: '',
  });

  // dane osobowe
  const [personalData, setPersonalData] = useState({
    name: '',
    surname: '',
    mail: '',
    phone: '',
    company: '',
    city: '',
    addInfo: '',
  });

  //koszyk
  const [cart, setCart] = useState([]);

  //next step button
  const [isFormValid, setIsFormValid] = useState(false);
  const classes = useStyles();

  function handleClick() {
    if (plannerStep < 4) {
      setPlannerStep(plannerStep + 1);
    }
  }

  function handleClickReturn() {
    if (plannerStep <= 4) {
      setPlannerStep(plannerStep - 1);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <Navigation navigationStep={plannerStep} />
          </div>
        </div>

        <div className="row">
          <div className="col col-12">
            <div className={classes.content}>
              {plannerStep === 1 ? (
                <Questionnaire
                  questionnaireData={questionnaireData}
                  setQuestionnaireData={setQuestionnaireData}
                  setIsFormValid={setIsFormValid}
                />
              ) : (
                ''
              )}
              {plannerStep === 2 ? (
                <Services
                  peopleNumber={questionnaireData.peopleNumber}
                  cart={cart}
                  setCart={setCart}
                />
              ) : (
                ''
              )}
              {plannerStep === 3 ? (
                <PersonalData
                  personalData={personalData}
                  setPersonalData={setPersonalData}
                  cart={cart}
                  setCart={setCart}
                  setIsFormValid={setIsFormValid}
                />
              ) : (
                ''
              )}
              {plannerStep === 4 ? (
                <Summary
                  cart={cart}
                  questionnaireData={questionnaireData}
                  personalData={personalData}
                />
              ) : (
                ''
              )}
              <div className={classes.buttons}>
                {plannerStep !== 1 && plannerStep !== 4 ? (
                  <button
                    className="btn turquoise-btn"
                    onClick={handleClickReturn}
                  >
                    Poprzedni krok
                  </button>
                ) : (
                  <p></p>
                )}

                {plannerStep !== 4 ? (
                  <button
                    className="btn blue-btn"
                    onClick={handleClick}
                    disabled={!isFormValid}
                  >
                    Następny krok
                  </button>
                ) : (
                  <p></p>
                )}
              </div>
            </div>
            {plannerStep == 4 ? (
              <div className={classes.summaryBoxes}>
                <div className="box-wrapper">
                  <img src={download} alt="" />
                  <h5>Pobierz oraz wydrukuj podsumowanie</h5>
                  <button class="btn border-btn">Podsumowanie</button>
                </div>
                <div className="box-wrapper">
                  <img src={contact} alt="" />
                  <h5>Pytania? Zadzwoń lub napisz do nas wiadomość!</h5>
                  <button class="btn pink-border-btn">Kontakt</button>
                </div>
              </div>
            ) : (
              ''
            )}{' '}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default App;
