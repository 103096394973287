import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  content: {
    width: '90%',
    margin: 'auto',
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    padding: '3rem 4rem',
    '& h5': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '4rem',
    },
    '@media(max-width:1200px)': {
      width: '100%',
    },
    '@media(max-width:768px)': {
      padding: '2rem',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& button': {
      marginBottom: '1rem',
    },
    '& .turquoise-btn': {
      marginRight: '1rem',
    },
  },
  summaryBoxes: {
    width: '90%',
    margin: 'auto',
    display: 'flex',
    marginTop: '2.5rem',
    '& .box-wrapper': {
      backgroundColor: 'white',
      borderRadius: '1.4rem',
      padding: '2rem',
      '& h5': {
        fontWeight: '600',
        color: '#42515A',
        margin: '1.5rem 0 2rem 0',
      },
      '&:first-of-type': {
        maxWidth: '37rem',
        marginRight: '2.5rem',
      },
      '&:last-of-type': {
        maxWidth: '42rem',
      },
    },
    '@media(max-width:1200px)': {
      width: '100%',
    },
    '@media(max-width:768px)': {
      flexDirection: 'column',
      '& .box-wrapper': {
        '&:first-of-type': {
          maxWidth: 'unset !important',
          marginRight: '0 !important',
          marginBottom: '2rem',
        },
        '&:last-of-type': {
          maxWidth: 'unset !important',
        },
      },
    },
  },
});

export default useStyles;
