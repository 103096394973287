import { createUseStyles } from 'react-jss';
const useStyles = createUseStyles({
  personalData: {},
  formWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      '&:nth-of-type(2n)': {
        paddingLeft: '2rem',
        '@media(max-width:768px)': {
          paddingLeft: '0',
        },
      },
      '&:nth-of-type(2n+1)': {
        paddingRight: '2rem',
        '@media(max-width:768px)': {
          paddingRight: '0',
        },
      },
      '&:last-of-type': {
        width: '100%',
        paddingRight: '0',
      },
    },
  },
  input: {
    width: '50%',
    marginBottom: '1.5rem',
    '@media(max-width:768px)': {
      width: '100%',
    },
    '& label': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '0.5rem',
      display: 'block',
    },
  },
});
export default useStyles;
