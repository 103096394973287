import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  questionnaire: {},
  mainInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > div': {
      '&:nth-of-type(2n)': {
        paddingLeft: '2rem',
        '@media(max-width:768px)': {
          paddingLeft: '0',
        },
      },
      '&:nth-of-type(2n+1)': {
        paddingRight: '2rem',
        '@media(max-width:768px)': {
          paddingRight: '0',
        },
      },
    },
  },
  input: {
    width: '50%',
    marginBottom: '1.5rem',

    '& label': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '0.5rem',
      display: 'block',
    },
    '@media(max-width:768px)': {
      width: '100%',
    },
  },

  checkbox: {
    width: '50%',
    marginBottom: '1.5rem',

    '& label': {
      fontWeight: '600',
      color: '#42515A',
      marginBottom: '0.5rem',
      display: 'block',
    },
    '& div': {
      display: 'flex',
      alignItems: 'center',
      padding: '1rem 1.4rem !important',
      borderRadius: '1rem',
      fontFamily: '"Montserrat", sans-serif',
      border: '2px solid #d9dcde !important',
      color: '#42515A',
      fontSize: '1.4rem',
      fontWeight: '600',
    },
    '@media(max-width:768px)': {
      width: '100%',
    },
  },

  hotel: {
    border: '2px solid #EFF2F3',
    borderRadius: '1.4rem',
    padding: '1.8rem',
    margin: '2rem 0',
    '& .title-wrapper': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem',
      '& h5': {
        marginBottom: '0rem',
        marginLeft: '1rem',
      },
    },

    '& .wrapper': {
      display: 'flex',
      flexWrap: 'wrap',
      '& > div': {
        '&:nth-of-type(2n)': {
          paddingLeft: '2rem',
          '@media(max-width:768px)': {
            paddingLeft: '0',
          },
        },
        '&:nth-of-type(2n+1)': {
          paddingRight: '2rem',
          '@media(max-width:768px)': {
            paddingRight: '0',
          },
        },
      },
    },
  },
});

export default useStyles;
