import { createUseStyles } from 'react-jss';

import rekreacja from '../../assets/svg/rekreacja.svg';
import sport from '../../assets/svg/sport.svg';
import rehabilitacja from '../../assets/svg/rehabilitacja.svg';
import specjalne from '../../assets/svg/specjalne.svg';

const useStyles = createUseStyles({
  services: {
    '& .title-wrapper': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem',
      '& h5': {
        marginBottom: '0rem',
        marginLeft: '1rem',
      },
    },
    '& .services-wrapper ': {
      marginBottom: '4rem',

      '& >h6': {
        color: '#42515A',
        fontWeight: '600',
        marginBottom: '1.8rem',
      },
      '& > div': {
        display: 'flex',
        flexWrap: 'wrap',
      },
    },
  },

  serviceCategories: {
    background: '#EFF2F3',
    borderRadius: '4.8rem',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '2.8rem',
    '& button': {
      border: 'none',
      backgroundColor: 'transparent',
      transition: 'all 250ms ease',
      borderRadius: '52px',
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '600',
      fontSize: '1.4rem',
      color: '#42515a',
      padding: '1.4rem 2.8rem',
      marginRight: '1rem',
      display: 'inline-flex',
      aligItems: 'center',
      '&.active': {
        boxShadow: '0px 12px 25px rgb(0 0 0 / 10%)',
        backgroundColor: 'white',
      },
      '&:before': {
        content: '""',
        width: '2.5rem',
        height: '1.7rem',
        backgroundImage: `url(${rekreacja})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        marginRight: '1rem',
      },
      '&:nth-of-type(2)': {
        '&:before': {
          backgroundImage: `url(${sport})`,
        },
      },

      '&:nth-of-type(3)': {
        '&:before': {
          backgroundImage: `url(${rehabilitacja})`,
        },
      },

      '&:nth-of-type(4)': {
        '&:before': {
          backgroundImage: `url(${specjalne})`,
        },
      },
    },
    '@media(max-width:992px)': {
      flexDirection: 'column',
      borderRadius: '3rem',
    },
  },

  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});
export default useStyles;
