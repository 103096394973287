import { createUseStyles } from 'react-jss';
import icon from '../../assets/svg/more-info.svg';

const useStyles = createUseStyles({
  cart: {
    border: '2px solid #EFF2F3',
    borderRadius: '1.4rem',
    padding: '1.8rem',
    marginBottom: '2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& >div': {
      display: 'flex',
      alignItems: 'center',
    },
    '@media(max-width:992px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& >div': {
        marginBottom: '1rem',
      },
    },
    '& div div': {
      marginLeft: '1rem',
      fontWeight: '600',
      color: '#42515A',
      '& span': {
        color: '#8E979C',
        fontSize: '1.2rem',
        display: 'inline-flex',
        alignItems: 'center',
        marginTop: '0.5rem',
        '&:before': {
          content: '""',
          minWidth: '1.6rem',
          minHeight: '1.6rem',
          marginRight: '0.5rem',
          backgroundImage: `url(${icon})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        },
      },
    },
  },
  modalCart: {
    position: 'relative',
    backgroundColor: 'white',
    borderRadius: '1.4rem',
    width: '53%',
    margin: 'auto',
    padding: '2rem 4rem',
    marginTop: '10rem',
    marginBottom: '5rem',
    '& .title-wrapper': {
      fontWeight: '600',
      color: '#42515A',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2rem',
      '@media(max-width:768px)': {
        paddingRight: '4rem',
      },

      '& img': {
        marginRight: '1rem',
      },
      '& ~p': {
        color: '#42515A',
        fontWeight: '600',
      },
    },

    '@media(max-width:1200px)': {
      width: '80%',
    },
    '@media(max-width:576px)': {
      padding: '2rem',
      marginTop: '2rem',
    },
  },
  closeModal: {
    position: 'absolute',
    top: '0',
    right: '-6rem',
    backgroundColor: 'transparent',
    width: '5.2rem',
    height: '5.2rem',
    padding: '0',
    border: 'none',
    '& img': {
      objectFit: 'contain',
      width: '100%',
      height: '100%',
    },
    '@media(max-width:768px)': {
      right: '0',
    },
  },
  cartItem: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: '600',
    justifyContent: 'space-between',
    borderBottom: '2px solid #EFF2F3',
    padding: '1rem 0',
    '&:last-of-type': {
      borderBottom: 'unset',
    },
    '& >div:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: '2',
      '& span': {
        fontSize: '1.2rem',
        color: '#71838D',
        marginTop: '0.5rem',
      },
    },
    '& p.price': {
      color: '#27BDBE',
      fontSize: '2rem',
      padding: '0 5rem ',
    },
    '& button.remove': {
      border: 'none',
      backgroundColor: 'transparent',
      minWidth: '3.3rem',
      minHeight: '3.3rem',
      '& img': {
        objectFit: 'contain',
      },
    },
    '@media(max-width:768px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& p.price': {
        padding: '1rem 0',
      },
    },
  },
});
export default useStyles;
